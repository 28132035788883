* {
  border-radius: 0 !important;
}

.inactive {
  background-color: #2a323a;
}

table {
  &.table {
    font-size: 14px;
  }
}

.has-error {
  label {
    color: red !important;
  }
}